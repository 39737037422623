<template>
    <div id="sidebar-wrapper">
        <div class="col-12 brand d-flex mt-4">
            <img class="me-4 mt-2" :src="require('../assets/img/ghs-logo.png')" alt="GHS Logo" style="width: 35px; height: 34px;">
            <span class="wide mt-1">
                <a href="home" style="color: #666666;">
                    Gear Health System
                </a>
                
                <p>Driving Quality Healthcare</p>
            </span>
           <span class="close mt-2" v-on:click="close()">
                <font-awesome-icon class="icon float-right" :icon="['fa', 'times']" /> 
            </span>            
        </div>


        <div class="list-nav list-group list-group-flush mt-3">
            <li v-for="({title, link, icon, if_children, children, modal, index}) in sidebar_data" :key="index" :class="['list-group-item', {'active': page == title}]">
                <router-link v-if="!modal && !if_children" :to="'/' + link" class="d-flex">
                    <span>
                        <font-awesome-icon class="icon float-right" :icon="['fa', icon]" /> 
                        <span class="list-name"> {{ title }} </span>  
                    </span>
                </router-link> 

                <a v-if="modal && !if_children" class="dropdown-item" data-bs-toggle="modal" :data-bs-target="'#' + link">
                    <span>
                        <font-awesome-icon class="icon" :icon="['fa', 'user']" /> 
                        <span class="list-name"> {{ title }}  </span>  
                    </span>
                </a>

                <a v-if="if_children" class="d-flex" @click="expand($event.currentTarget)">
                    <span>
                        <font-awesome-icon class="icon" :icon="['fa', icon]" /> 
                        <span class="list-name"> {{ title }} </span>  
                    </span>

                    <font-awesome-icon class="icon me-0" :icon="['fa', 'caret-down']" /> 
                </a>

                <ul v-if="if_children" class="list-nav-child list-group list-group-flush">
                    <li v-for="({child_title, child_link, child_icon, child_modal, child_index}) in children" :key="child_index" class="list-group-item">
                        <router-link v-if="!child_modal" :to="'/' + child_link" >
                            <font-awesome-icon class="icon" :icon="['fa', child_icon]" />  &nbsp; {{ child_title }}
                        </router-link>

                        <a v-if="child_modal" class="dropdown-item" data-bs-toggle="modal" :data-bs-target="'#' + child_link">
                            <font-awesome-icon class="icon" :icon="['fa', child_icon]" />  &nbsp; {{ child_title }}
                        </a>
                    </li>
                </ul>

            </li>
        </div>        
    </div>
</template>

<style lang="scss" scoped>
    @import '../assets/scss/layout/_sidebar.scss';
    #sidebar-wrapper {
        box-shadow: 0 1px 4px 0 rgb(0 0 0 / 15%);
    }
</style>

<script> 
export default {
    name: 'AutoSideBar',
    props: ['page', 'sidebar_data'],
    methods: {
        expand(event) {
            document.querySelectorAll('.list-nav > .list-group-item').forEach(list => { 
                if(list.querySelector('.list-name').textContent === event.querySelector('.list-name').textContent) {
                    if(list.querySelector('.list-nav-child').style.height === '0px' || list.querySelector('.list-nav-child').style.height === '') {
                        list.querySelector('.list-nav-child').style.opacity = '1'
                        list.querySelector('.list-nav-child').style.visibility = 'visible'
                        list.querySelector('.list-nav-child').style.height = 'auto'

                        if(list.querySelector('.list-nav-child').parentElement.className.includes('active')) {
                            list.querySelector('.list-nav-child').parentElement.querySelector('.list-group-item a.d-flex').classList.add('expand-active-list-item');
                        } else {
                            list.querySelector('.list-nav-child').parentElement.querySelector('.list-group-item a.d-flex').classList.add('expand-list-item');
                        }
                    } else {
                        list.querySelector('.list-nav-child').style.height = '0px'
                        list.querySelector('.list-nav-child').style.visibility = 'hidden'
                        list.querySelector('.list-nav-child').style.opacity = '0'

                        if(list.querySelector('.list-nav-child').parentElement.className.includes('active')) {
                            list.querySelector('.list-nav-child').parentElement.querySelector('.list-group-item a.d-flex').classList.remove('expand-active-list-item');
                        } else {
                            list.querySelector('.list-nav-child').parentElement.querySelector('.list-group-item a.d-flex').classList.remove('expand-list-item');
                        }
                    }
                }
            });
        },

        close() {
            document.querySelector('#sidebar-wrapper').classList.remove('toggled');
            document.querySelector('.backdrop').classList.remove('on');
        }
    }
}
</script>